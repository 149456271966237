import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { logout } from 'redux/actions/authActions';
import axios from 'axios';

export const useAccountLogout = () => {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  // Fetch CSRF cookie before making POST requests
  const fetchCsrfCookie = async () => {
    try {
      await axios.get(`${BASE_URL}/sanctum/csrf-cookie`, {
        withCredentials: true, // Include cookies in the request
        withXSRFToken: true
      });
    } catch (error) { 
      throw new Error('Failed to fetch CSRF cookie');
    }
  };

  const logoutUser = useCallback(async () => {
    await fetchCsrfCookie();
    await axios.post(`${BASE_URL}/auth/logout`, {}, {
      withCredentials: true,
      withXSRFToken: true
    });

    dispatch(logout());
    
    localStorage.removeItem("user");
  }, []);

  return logoutUser;

};
