import { useState, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useAccountLogout } from './useAccountLogout';
import { notifyError, handleErrorResponse } from 'helper/utils';

export function usePostData(apiEndpoint) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const REDIRECT_BASE_URL = process.env.REACT_APP_REDIRECT_BASE_URL;
  const {user} = useSelector(state => state.auth);
  const authToken = user && user.token;
  const logout = useAccountLogout();

  // Fetch CSRF cookie before making POST requests
  const fetchCsrfCookie = async () => {
    try {
      await axios.get(`${REDIRECT_BASE_URL}/sanctum/csrf-cookie`, {
        withCredentials: true, // Include cookies in the request
        withXSRFToken: true
      });
    } catch (error) { 
      throw new Error('Failed to fetch CSRF cookie');
    }
  };

  const postData = useCallback(async (postData) => {
    setLoading(true);
    setError(null);

    try {

      await fetchCsrfCookie();
      const responseData = await axios.post(`${BASE_URL}${apiEndpoint}`, postData, { withCredentials: true, withXSRFToken: true });
      setResponse(responseData.data);
      // Optionally, you can handle success or further actions here.
    } catch (error) {
      handleErrorResponse(error, setError, logout);
    } finally {
      setLoading(false);
    }
  }, [apiEndpoint]);

  return { postData, loading, error, response };
}
