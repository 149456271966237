// ** Import from react dom
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// ** Import core SCSS styles
import 'assets/scss/theme.scss';
import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import QuizResult from 'components/marketing/student/QuizResult';
import PaystackVerification from 'components/marketing/pages/courses/course-checkout/PaystackVerification';

const AuthLayout = lazy(() => import('layouts/dashboard/AuthLayout'));
const CourseFilterPage = lazy(() => import('components/marketing/pages/courses/course-filter-page/CourseFilterPage'));
const HelpCenterTransparentLayout = lazy(() => import('layouts/marketing/HelpCenterTransparentLayout'));
const SignIn = lazy(() => import('components/dashboard/authentication/SignIn'));
const SignUp = lazy(() => import('components/dashboard/authentication/SignUp'));
const ForgetPassword = lazy(() => import('components/dashboard/authentication/ForgetPassword'));
const BusinessRegistration = lazy(() => import('components/dashboard/authentication/BusinessRegistration'));
const EmailVerificationNotification = lazy(() => import('components/dashboard/authentication/EmailVerification'));
const NewPassword = lazy(() => import('components/dashboard/authentication/NewPassword'));
const DefaultLayout = lazy(() => import('layouts/marketing/DefaultLayout'));
const NotFound = lazy(() => import('layouts/marketing/NotFound'));
const CourseCategory = lazy(() => import('components/marketing/pages/courses/course-category/CourseCategory'));

const AutoLoginHandler = lazy(() => import('components/elements/AutoLoginHandler'));

/* IMPORTS FOR FRONT SIDE PAGES MENU */
const CourseSingle = lazy(() => import ('components/marketing/pages/courses/course-single/CourseSingle'));
const CourseResume = lazy(() => import ('components/marketing/pages/courses/course-resume/CourseResume'));
const CourseCheckout = lazy(() => import ('components/marketing/pages/courses/course-checkout/CourseCheckout'));
const CohortPage = lazy(() => import ('components/marketing/pages/cohort/CohortPage'));
const PaypalVerification = lazy(() => import ('components/marketing/pages/courses/course-checkout/PaypalVerification'));
const About = lazy(() => import ('components/marketing/pages/about/About'));
const Contact = lazy(() => import ('components/marketing/pages/contact/Contact')); // new v1.1.0
const BlankLayout = lazy(() => import ('layouts/marketing/BlankLayout'));

// IMPORTS FOR HELP CENTER PAGES ( v1.3.0 )
const HelpCenter = lazy(() => import('components/marketing/pages/help-center/help-center/HelpCenter'));
const HelpCenterFAQ = lazy(() => import('components/marketing/pages/help-center/help-center-faq/HelpCenterFAQ'));
const HelpCenterGuide = lazy(() => import('components/marketing/pages/help-center/help-center-guide/HelpCenterGuide'));
const HelpCenterGuideSingle = lazy(() => import('components/marketing/pages/help-center/help-center-guide-single/HelpCenterGuideSingle'));
const HelpCenterSupport = lazy(() => import('components/marketing/pages/help-center/help-center-support/HelpCenterSupport'));

/* IMPORTS FOR FRONT SPECIALTY SUBMENU  ROUTERS */
const Error404 = lazy(() => import('components/marketing/pages/specialty/Error404'));

const HomeAcademy = lazy(() => import('components/marketing/landings/home-academy/HomeAcademy')); // new v2.0.0

// Student Dashboard Pages
const StudentDashboard = lazy(() => import ('components/marketing/student/Dashboard'));
const DeleteProfile = lazy(() => import ('components/marketing/account-settings/DeleteProfile'));
const EditProfile = lazy(() => import ('components/marketing/account-settings/EditProfile'));
const LinkedAccounts = lazy(() => import ('components/marketing/account-settings/LinkedAccounts'));
const AccountNotifications = lazy(() => import ('components/marketing/account-settings/Notifications'));
const StudentPayment = lazy(() => import ('components/marketing/account-settings/Payment'));
const ProfilePrivacy = lazy(() => import ('components/marketing/account-settings/ProfilePrivacy'));
const Security = lazy(() => import ('components/marketing/account-settings/Security'));
const SocialProfiles = lazy(() => import ('components/marketing/account-settings/SocialProfiles'));
const Subscriptions = lazy(() => import ('components/marketing/account-settings/Subscriptions'));

const Achievements = lazy(() => import ('components/marketing/account-settings/Achievements'));
//const CourseLearning = lazy(() => import ('components/marketing/account-settings/CourseLearning'));
const Discussion = lazy(() => import ('components/marketing/account-settings/Discussion'));
const QuizPage = lazy(() => import ('components/marketing/account-settings/QuizPage'));
const StudentQuizResult = lazy(() => import ('components/marketing/account-settings/quiz/QuizResult'));
const SupportTicket = lazy(() => import ('components/marketing/account-settings/SupportTicket'));

// Student New Dashboard Pages for Quiz ( v2.0.0)
const StudentQuiz = lazy(() => import ('components/marketing/student/Quiz'));
const StudentQuizStart = lazy(() => import ('components/marketing/student/quiz-start/QuizStart'));
const StudentQuizAttempt = lazy(() => import ('components/marketing/student/QuizAttempt'));
// const StudentQuizResult = lazy(() => import ('components/marketing/student/QuizResult'));


// Instructor Dashboard Pages
const InstructorDashboard = lazy(() => 'components/marketing/instructor/Dashboard');
const InstructorMyCourses = lazy(() => 'components/marketing/instructor/MyCourses');
const InstructorReviews = lazy(() => 'components/marketing/instructor/Reviews');
const Earnings = lazy(() => 'components/marketing/instructor/Earnings');
const InstructorOrders = lazy(() => 'components/marketing/instructor/Orders');
const InstructorStudents = lazy(() => 'components/marketing/instructor/Students');
const ViewProfile = lazy(() => 'components/marketing/instructor/ViewProfile');

// Instructor New Dashboard Pages for Quiz ( v2.0.0)
const InstructorQuiz = lazy(() => 'components/marketing/instructor/Quiz');
const InstructorQuizSingle = lazy(() => 'components/marketing/instructor/QuizSingle');
const InstructorQuizResult = lazy(() => 'components/marketing/instructor/QuizResult');


// Account Settings
const BillingInfo = lazy(() => import ('components/marketing/account-settings/BillingInfo'));
const Invoice = lazy(() => import ('components/marketing/account-settings/Invoice'));
const InvoiceDetails = lazy(() => import ('components/marketing/account-settings/InvoiceDetails'));
const ProtectedRoute = lazy(() => import ('./ProtectedRoute'));

const CourseTrial = lazy(() => import ('components/marketing/pages/courses/course-subscription/CourseTrial'));
const CourseSubscription = lazy(() => import ('components/marketing/pages/courses/course-subscription/CourseSubscription'));
const VerifyAccount = lazy(() => import ('components/dashboard/authentication/VerifyAccount'));
const PrivacyPolicy = lazy(() => import('components/marketing/pages/terms/PrivacyPolicy'));
const TermsConditions = lazy(() => import('components/marketing/pages/terms/TermsConditions'));


const AllRoutes = () => {
	const {user} = useSelector((state) => state.auth);
	return (
			<Routes>
				{/* Routes with DefaultLayout */}
				<Route element={<DefaultLayout />}>
					<Route element={
						<ProtectedRoute 
							isLogin={Boolean(user && user?.isVerified !== null)} 
							redirectPath={user && user?.isVerified == null ? "/verify/account" : ""} 
						/>}>

						<Route
							path="/payment/verification/"
							element={<PaypalVerification />}
						/>

						<Route
							path="/paystack/verification/:reference/"
							element={<PaystackVerification />}
						/>

						<Route
							path="/student/dashboard/"
							element={<StudentDashboard />}
						/>
						<Route
							path="/student/student-subscriptions/"
							element={<Subscriptions />}
						/>
						<Route
							path="/student/student-billing-info/"
							element={<BillingInfo />}
						/>
						<Route
							path="/student/student-payment/"
							element={<StudentPayment />}
						/>
						<Route
							path="/student/student-invoice/"
							element={<Invoice />}
						/>

						<Route
							path="/student/ticket/"
							element={<SupportTicket />}
						/>
						<Route
							path="/student/quiz-start/"
							element={<QuizPage />}
						/>

						<Route
							path="/student/quiz-result/"
							element={<QuizResult />}
						/>
						<Route
							path="/student/achievements/"
							element={<Achievements />}
						/>
						
						{/* <Route
							path="/student/learning/"
							element={<CourseLearning />}
						/> */}

						<Route
							path="/student/student-invoice-details/"
							element={<InvoiceDetails />}
						/>
						<Route
							path="/student/student-edit-profile/"
							element={<EditProfile />}
						/>
						<Route
							path="/student/student-security/"
							element={<Security />}
						/>
						<Route
							path="/student/student-social-profiles/"
							element={<SocialProfiles />}
						/>
						<Route
							path="/student/student-notifications/"
							element={<AccountNotifications />}
						/>
						<Route
							path="/student/student-profile-privacy/"
							element={<ProfilePrivacy />}
						/>
						<Route
							path="/student/student-delete-profile/"
							element={<DeleteProfile />}
						/>
						<Route
							path="/student/student-linked-accounts/"
							element={<LinkedAccounts />}
						/>

						<Route
							path="/instructor/dashboard/"
							element={<InstructorDashboard />}
						/>
						<Route
							path="/instructor/instructor-my-courses/"
							element={<InstructorMyCourses />}
						/>
						<Route
							path="/instructor/instructor-reviews/"
							element={<InstructorReviews />}
						/>
						<Route
							path="/instructor/instructor-earnings/"
							element={<Earnings />}
						/>
						<Route
							path="/instructor/instructor-orders/"
							element={<InstructorOrders />}
						/>
						<Route
							path="/instructor/instructor-students/"
							element={<InstructorStudents />}
						/>
						
						<Route
							path="/instructor/instructor-edit-profile/"
							element={<EditProfile />}
						/>
						<Route
							path="/instructor/instructor-profile/"
							element={<ViewProfile />}
						/>
						<Route
							path="/instructor/instructor-security/"
							element={<Security />}
						/>
						<Route
							path="/instructor/instructor-social-profiles/"
							element={<SocialProfiles />}
						/>
						<Route
							path="/instructor/instructor-notifications/"
							element={<AccountNotifications />}
						/>
						<Route
							path="/instructor/instructor-profile-privacy/"
							element={<ProfilePrivacy />}
						/>
						<Route
							path="/instructor/instructor-delete-profile/"
							element={<DeleteProfile />}
						/>
						<Route
							path="/instructor/quiz/"
							element={<InstructorQuiz />}
						/>
						<Route
							path="/instructor/quiz/single/"
							element={<InstructorQuizSingle />}
						/>
						<Route
							path="/instructor/quiz/result/"
							element={<InstructorQuizResult />}
						/>

					</Route>

					{/* Individual protected route outside the group */}
					<Route element={
							<ProtectedRoute 
								isLogin={Boolean(user && user?.isVerified !== null)} 
								redirectPath={user && user?.isVerified == null ? "/verify/account" : "/auth/register"} 
							/>}>

						<Route path="/courses/course-checkout/" element={<CourseCheckout />} />
					</Route>

				</Route>

				<Route element={<ProtectedRoute isLogin={Boolean(user && user?.isVerified !== null)} redirectPath={user && user?.isVerified == null ? "/verify/account" : ""} />}>
					{/* Routes with BlankLayout */}
					<Route path="/courses/course-resume/:slug" element={<CourseResume />} />
					<Route path="/student/quiz/:quizId" element={<StudentQuiz />} />
					<Route path="/student/quiz/attempt/" element={<StudentQuizAttempt />} />
					<Route path="/student/quiz/start/" element={<StudentQuizStart />} />
					<Route path="/student/quiz/result/:attemptId/" element={<StudentQuizResult />} />
				</Route>


			{/* Routes with DefaultLayout */}
			<Route element={<DefaultLayout />}>

			{/* Routes with AcademyLayout */}
			<Route path="/" element={<HomeAcademy />} />
			<Route path="/verify/account" element={<VerifyAccount />} />

			<Route
				path="/student/discussion/"
				element={<Discussion />}
			/>

			<Route
				path="/course-category/:slug"
				element={<CourseCategory />}
			/>

			<Route
				path="/course/trial"
				element={<CourseTrial />}
			/>

			<Route
				path="/course/subscription"
				element={<CourseSubscription />}
			/>

			<Route
				path="/courses/course-single/:slug"
				element={<CourseSingle />}
			/>

			<Route
				path='/courses/filter'
				element={<CourseFilterPage />}
			/>

			<Route path="/cohort/" element={<CohortPage />} />
			
			</Route>

			{/* Routes with BlankLayout */}
			<Route element={<BlankLayout />}>
				<Route path="/about/" element={<About />} />
				<Route path="/contact/" element={<Contact />} />
				<Route path="/terms" element={<TermsConditions />} />
				<Route path='/privacy' element={<PrivacyPolicy />} />
			</Route>


			{/* Routes with HelpCenterTransparentLayout */}
			<Route element={<HelpCenterTransparentLayout />}>
			<Route path="/help-center/" element={<HelpCenter />} />
			</Route>

			{/* Routes with HelpCenterLayout */}
			<Route element={<DefaultLayout />}>
			<Route path="/help-center/faq/" element={<HelpCenterFAQ />} />
			<Route
				path="/help-center/guide/"
				element={<HelpCenterGuide />}
			/>

			<Route
				path="/help-center/guide-single/:categoryslug/:articleslug"
				element={<HelpCenterGuideSingle />}
			/>
			<Route
				path="/help-center/support/"
				element={<HelpCenterSupport />}
			/>
			</Route>

			<Route
				path="/verify-login"
				element={<AutoLoginHandler />}
			/>

			{/* Routes with AuthLayout */}
			<Route element={<AuthLayout />}>
			<Route path="/auth/business" element={<BusinessRegistration />} />
			<Route path="/auth/email/verification" element={<EmailVerificationNotification />} />
			<Route path="/auth/new/password" element={<NewPassword />} />
			<Route path="/auth/login" element={<SignIn />} />
			<Route path="/auth/register" element={<SignUp />} />
			<Route path="/auth/forget/password" element={<ForgetPassword />} />
			</Route> 

				{/* Routes with NotFound */}
				<Route element={<NotFound />}>
					<Route path="/specialty/404-error/" element={<Error404 />} />
				</Route>

				{/*Redirect*/}
				<Route
					path="*"
					element={<Navigate to="/specialty/404-error/" replace />}
				/>
			</Routes>
	);
};

export default AllRoutes;
