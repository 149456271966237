import { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
// import { useSelector } from 'react-redux';
import { handleErrorResponse } from 'helper/utils';
import { useAccountLogout } from './useAccountLogout';

const fetchApiData = async (apiEndpoint, userTimezone, BASE_URL, queryParams) => {

  axios.defaults.headers.common['User-Timezone'] = userTimezone;
  // Construct URL with query parameters
  const url = new URL(`${BASE_URL}${apiEndpoint}`);
  if (queryParams) {
    Object.keys(queryParams).forEach(key => {
      url.searchParams.append(key, queryParams[key]);
    });
  }

  const response = await axios.get(url.toString(), { withCredentials: true, withXSRFToken: true });
  return response.data;
};

export function useFetchData(apiEndpoint, queryParams = {}) {
  // const { user } = useSelector((state) => state.auth);
  // const authToken = user?.token;
  const [errors, setError] = useState(null);
  const logout = useAccountLogout();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const { data, error, isLoading, refetch } = useQuery(
    ['fetchData', apiEndpoint, queryParams],
    () => fetchApiData(apiEndpoint, userTimezone, BASE_URL, queryParams),
    {
      enabled: !!apiEndpoint, // Only run query if apiEndpoint is provided
      refetchOnWindowFocus: false, // Disable refetching on window focus
      staleTime: 5 * 60 * 1000, // 5 minutes, adjust as needed
      cacheTime: 30 * 60 * 1000, // 30 minutes, adjust as needed
      onError: (err) => {
        handleErrorResponse(err, setError, logout);
      },
    }
  );

  return {
    data,
    loading: isLoading,
    error,
    refreshData: refetch,
  };
}